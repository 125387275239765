import {
    BehanceIcon,
    DribbbleIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    MediumIcon,
    TiktokIcon,
    TwitchIcon,
    TwitterIcon,
    VimeoIcon,
    WhatsappIcon,
    YoutubeIcon,
} from '@keepoinc/site-page-react-component';
import { useTranslation } from 'react-i18next';

import Input from '../../../../components/Form/Input/input';
import { Form, FormGroup } from '../../../../components/Form/styles';
import Header from '../../../../components/Header';
import { Box } from '../../../../components/Layout';
import SaveBar from '../../../../components/SaveBar';
import { SocialSection } from '../../../../model/site';
import { useSection } from '../../hooks/Section';
import { sanitizeSocialLink, socialPaths } from './sanitize';
import { Container, PhoneInput } from './styles';

const SocialPage = () => {
    const { t } = useTranslation();

    const { section, setSection, saveSection, hasUnsavedChanges } =
        useSection<SocialSection>('social');

    const saveHandler = () => {
        saveSection({
            ...section,
            facebook: sanitizeSocialLink(
                socialPaths.facebook,
                section.facebook
            ),
            instagram: sanitizeSocialLink(
                socialPaths.instagram,
                section.instagram
            ),
            twitter: sanitizeSocialLink(socialPaths.twitter, section.twitter),
            youtube: sanitizeSocialLink(socialPaths.youtube, section.youtube),
            linkedin: sanitizeSocialLink(
                socialPaths.linkedin,
                section.linkedin
            ),
            behance: sanitizeSocialLink(socialPaths.behance, section.behance),
            dribbble: sanitizeSocialLink(
                socialPaths.dribbble,
                section.dribbble
            ),
            medium: sanitizeSocialLink(socialPaths.medium, section.medium),
            twitch: sanitizeSocialLink(socialPaths.twitch, section.twitch),
            tiktok: sanitizeSocialLink(socialPaths.tiktok, section.tiktok),
            vimeo: sanitizeSocialLink(socialPaths.vimeo, section.vimeo),
        });
    };

    return (
        <Container>
            <Header
                title={t('pages.editor.social.header.title')}
                hasUnsavedChanges={hasUnsavedChanges}
            />
            <Box>
                <Form>
                    <FormGroup>
                        <Input
                            id="facebook"
                            type="link"
                            autoCapitalize="none"
                            placeholder={t(
                                'pages.editor.social.form.facebook.placeholder'
                            )}
                            value={section.facebook || ''}
                            label="Facebook"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    facebook: e.target.value,
                                })
                            }
                            icon={
                                <FacebookIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="instagram"
                            type="link"
                            placeholder={t(
                                'pages.editor.social.form.instagram.placeholder'
                            )}
                            autoCapitalize="none"
                            value={section.instagram || ''}
                            label="Instagram"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    instagram: e.target.value,
                                })
                            }
                            icon={
                                section.instagram ? (
                                    <InstagramIcon
                                        width={24}
                                        height={24}
                                        color="white"
                                        background="var(--color-neutral-darkness)"
                                    />
                                ) : (
                                    <InstagramIcon
                                        width={24}
                                        height={24}
                                        color="var(--color-neutral-light)"
                                        background="#020202"
                                    />
                                )
                            }
                            ignoreNoValueIconClass
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="twitter"
                            type="link"
                            placeholder={t(
                                'pages.editor.social.form.twitter.placeholder'
                            )}
                            autoCapitalize="none"
                            value={section.twitter || ''}
                            label="Twitter"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    twitter: e.target.value,
                                })
                            }
                            icon={
                                <TwitterIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="youtube"
                            type="link"
                            autoCapitalize="none"
                            value={section.youtube || ''}
                            label="Youtube"
                            placeholder={t(
                                'pages.editor.social.form.youtube.placeholder'
                            )}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    youtube: e.target.value,
                                })
                            }
                            icon={
                                <YoutubeIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="linkedin"
                            type="link"
                            placeholder={t(
                                'pages.editor.social.form.linkedin.placeholder'
                            )}
                            autoCapitalize="none"
                            value={section.linkedin || ''}
                            label="Linkedin"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    linkedin: e.target.value,
                                })
                            }
                            icon={
                                <LinkedinIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="whatsapp"
                            type="link"
                            autoCapitalize="none"
                            value={''}
                            label="Whatsapp"
                            ignoreNoValueIconClass
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    whatsapp: e.target.value,
                                })
                            }
                            icon={
                                (section.whatsapp?.replace(/[^\d]/g, '')
                                    .length || 0) > 2 ? (
                                    <WhatsappIcon
                                        width={24}
                                        height={24}
                                        color="white"
                                        background="var(--color-neutral-darkness)"
                                    />
                                ) : (
                                    <WhatsappIcon
                                        width={24}
                                        height={24}
                                        color="var(--color-neutral-light)"
                                        background="#020202"
                                    />
                                )
                            }
                            input={
                                <PhoneInput
                                    mask="+55 (99) 9 9999-9999"
                                    id="whatsapp"
                                    name="whatsapp"
                                    type="tel"
                                    value={section.whatsapp || ''}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setSection({
                                            ...section,
                                            whatsapp: e.target.value.replace(
                                                /\D/g,
                                                ''
                                            ),
                                        })
                                    }
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="behance"
                            placeholder={t(
                                'pages.editor.social.form.behance.placeholder'
                            )}
                            type="link"
                            autoCapitalize="none"
                            value={section.behance || ''}
                            label="Behance"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    behance: e.target.value,
                                })
                            }
                            icon={
                                <BehanceIcon
                                    size={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="dribbble"
                            type="link"
                            autoCapitalize="none"
                            placeholder={t(
                                'pages.editor.social.form.dribbble.placeholder'
                            )}
                            value={section.dribbble || ''}
                            label="Dribbble"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    dribbble: e.target.value,
                                })
                            }
                            icon={
                                <DribbbleIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="medium"
                            type="link"
                            autoCapitalize="none"
                            placeholder={t(
                                'pages.editor.social.form.medium.placeholder'
                            )}
                            value={section.medium || ''}
                            label="Medium"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    medium: e.target.value,
                                })
                            }
                            icon={
                                <MediumIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="twitch"
                            type="link"
                            placeholder={t(
                                'pages.editor.social.form.twitch.placeholder'
                            )}
                            autoCapitalize="none"
                            value={section.twitch || ''}
                            label="Twitch"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    twitch: e.target.value,
                                })
                            }
                            icon={
                                <TwitchIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="tiktok"
                            type="link"
                            autoCapitalize="none"
                            placeholder={t(
                                'pages.editor.social.form.tiktok.placeholder'
                            )}
                            value={section.tiktok || ''}
                            label="Tiktok"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    tiktok: e.target.value,
                                })
                            }
                            icon={
                                <TiktokIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="vimeo"
                            type="link"
                            autoCapitalize="none"
                            placeholder={t(
                                'pages.editor.social.form.vimeo.placeholder'
                            )}
                            value={section.vimeo || ''}
                            label="Vimeo"
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    vimeo: e.target.value,
                                })
                            }
                            icon={
                                <VimeoIcon
                                    width={24}
                                    height={24}
                                    color="white"
                                    background="var(--color-neutral-darkness)"
                                />
                            }
                        />
                    </FormGroup>
                </Form>
            </Box>
            <SaveBar
                onSave={saveHandler}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        </Container>
    );
};

export default SocialPage;
